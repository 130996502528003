import React from "react";
import "./App.scss";

function App() {
  return (
    <div className="App">
      <main className="App-content">
        <h1 className="fade-in">Hey, I&#x27;m <b>Dan</b>.</h1>
        <h2 className="fade-in" style={{ marginTop: 8 }}>I do tech.</h2>
        <h3 className="fade-in" style={{marginTop: 12}}>
          <a className="App-Link" rel="noreferrer" target="_blank"
             href="https://docs.google.com/document/d/1XdDtjYET5SUmUNIpVweYSJhp5Bc9xQgYGSE3IQ-UPlQ/edit?usp=sharing">
            CV
          </a>
          {' '}available if needed.
        </h3>
      </main>
    </div>
  );
}

export default App;
